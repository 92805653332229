const filter = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.7599 6.21376C7.7599 5.12681 6.86462 4.24192 5.76229 4.24192C4.66113 4.24192 3.76467 5.12681 3.76467 6.21376C3.76467 7.30072 4.66113 8.18561 5.76229 8.18561C6.86462 8.18561 7.7599 7.30072 7.7599 6.21376ZM9.52457 6.21376C9.52457 8.26109 7.83754 9.92753 5.76229 9.92753C3.68821 9.92753 2 8.26109 2 6.21376C2 4.16643 3.68821 2.5 5.76229 2.5C7.83754 2.5 9.52457 4.16643 9.52457 6.21376ZM22 6.214C22 5.73323 21.6047 5.34304 21.1177 5.34304H13.8825C13.3954 5.34304 13.0002 5.73323 13.0002 6.214C13.0002 6.69476 13.3954 7.08495 13.8825 7.08495H21.1177C21.6047 7.08495 22 6.69476 22 6.214ZM16.2397 17.7862C16.2397 18.8732 17.135 19.7581 18.2374 19.7581C19.3397 19.7581 20.235 18.8732 20.235 17.7862C20.235 16.6981 19.3397 15.8144 18.2374 15.8144C17.135 15.8144 16.2397 16.6981 16.2397 17.7862ZM14.4751 17.7862C14.4751 15.7377 16.1621 14.0725 18.2374 14.0725C20.3126 14.0725 21.9996 15.7377 21.9996 17.7862C21.9996 19.8336 20.3126 21.5 18.2374 21.5C16.1621 21.5 14.4751 19.8336 14.4751 17.7862ZM2.88316 16.9149H10.1172C10.6042 16.9149 10.9995 17.3051 10.9995 17.7859C10.9995 18.2667 10.6042 18.6568 10.1172 18.6568H2.88316C2.39611 18.6568 2.00082 18.2667 2.00082 17.7859C2.00082 17.3051 2.39611 16.9149 2.88316 16.9149Z'
      fill='#58536D'
    />
  </svg>
);

const twitter = (
  <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.2857 1.34934C13.75 1.61921 13.2143 1.70917 12.5893 1.79913C13.2143 1.4393 13.6607 0.899563 13.8393 0.179913C13.3036 0.539738 12.6786 0.719651 11.9643 0.899563C11.4286 0.359825 10.625 0 9.82143 0C8.30357 0 6.96429 1.34935 6.96429 2.96856C6.96429 3.23843 6.96429 3.41834 7.05357 3.59825C4.64286 3.5083 2.41071 2.33886 0.982143 0.539738C0.714286 0.98952 0.625 1.4393 0.625 2.069C0.625 3.05852 1.16071 3.95808 1.96429 4.49782C1.51786 4.49782 1.07143 4.3179 0.625 4.13799C0.625 5.57729 1.60714 6.74673 2.94643 7.01659C2.67857 7.10655 2.41071 7.10655 2.14286 7.10655C1.96429 7.10655 1.78571 7.10655 1.60714 7.01659C1.96429 8.18603 3.03571 9.08559 4.375 9.08559C3.39286 9.8952 2.14286 10.345 0.714286 10.345C0.446429 10.345 0.267857 10.345 0 10.345C1.33929 11.1546 2.85714 11.6943 4.46429 11.6943C9.82143 11.6943 12.7679 7.19651 12.7679 3.32838C12.7679 3.23843 12.7679 3.05852 12.7679 2.96856C13.3929 2.51878 13.9286 1.97904 14.2857 1.34934Z'
      fill='#58536D'
      fillOpacity='0.9'
    />
  </svg>
);

const error = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM15.0098 14.9992C15.3498 14.6602 15.3498 14.1102 15.0098 13.7702L13.2298 11.9902L15.0098 10.2092C15.3498 9.87021 15.3498 9.31021 15.0098 8.97021C14.6698 8.62921 14.1198 8.62921 13.7698 8.97021L11.9998 10.7492L10.2198 8.97021C9.8698 8.62921 9.3198 8.62921 8.9798 8.97021C8.6398 9.31021 8.6398 9.87021 8.9798 10.2092L10.7598 11.9902L8.9798 13.7602C8.6398 14.1102 8.6398 14.6602 8.9798 14.9992C9.1498 15.1692 9.3798 15.2602 9.5998 15.2602C9.8298 15.2602 10.0498 15.1692 10.2198 14.9992L11.9998 13.2302L13.7798 14.9992C13.9498 15.1802 14.1698 15.2602 14.3898 15.2602C14.6198 15.2602 14.8398 15.1692 15.0098 14.9992Z'
      fill='#FF6752'
    />
  </svg>
);

const success = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM11.4301 14.9899L16.1801 10.2399C16.5201 9.89991 16.5201 9.34991 16.1801 8.99991C15.8401 8.65991 15.2801 8.65991 14.9401 8.99991L10.8101 13.1299L9.06011 11.3799C8.72011 11.0399 8.16011 11.0399 7.82011 11.3799C7.48011 11.7199 7.48011 12.2699 7.82011 12.6199L10.2001 14.9899C10.3701 15.1599 10.5901 15.2399 10.8101 15.2399C11.0401 15.2399 11.2601 15.1599 11.4301 14.9899Z'
      fill='#2CBF93'
    />
  </svg>
);

const errorOutline = (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.00004 2.33325C4.87537 2.33325 2.33337 4.87525 2.33337 7.99992C2.33337 11.1246 4.87537 13.6666 8.00004 13.6666C11.1247 13.6666 13.6667 11.1246 13.6667 7.99992C13.6667 4.87525 11.1247 2.33325 8.00004 2.33325ZM8.00004 14.6666C4.32404 14.6666 1.33337 11.6759 1.33337 7.99992C1.33337 4.32392 4.32404 1.33325 8.00004 1.33325C11.676 1.33325 14.6667 4.32392 14.6667 7.99992C14.6667 11.6759 11.676 14.6666 8.00004 14.6666Z'
      fill='#FF6752'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.99609 8.91524C7.72009 8.91524 7.49609 8.69124 7.49609 8.41524V5.46924C7.49609 5.19324 7.72009 4.96924 7.99609 4.96924C8.27209 4.96924 8.49609 5.19324 8.49609 5.46924V8.41524C8.49609 8.69124 8.27209 8.91524 7.99609 8.91524Z'
      fill='#FF6752'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.00264 11.1971C7.63397 11.1971 7.33264 10.8991 7.33264 10.5304C7.33264 10.1618 7.62797 9.86377 7.99597 9.86377H8.00264C8.37131 9.86377 8.66931 10.1618 8.66931 10.5304C8.66931 10.8991 8.37131 11.1971 8.00264 11.1971Z'
      fill='#FF6752'
    />
  </svg>
);

const building = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9918 4.17144L12.0001 4.1671L12.0089 4.17163L16.4623 6.8437L18.0001 7.77022V18.8003C18.0001 19.3933 17.9986 19.7179 17.9795 19.951L17.9772 19.9774L17.9509 19.9797C17.7177 19.9987 17.3932 20.0003 16.8001 20.0003H7.20012C6.60707 20.0003 6.28256 19.9987 6.04939 19.9797L6.02302 19.9774L6.02073 19.951C6.00168 19.7179 6.00012 19.3933 6.00012 18.8003V7.77025L7.53229 6.84712L11.9918 4.17144ZM6.50181 5.13303L1.48566 8.14272C1.01208 8.42687 0.85851 9.04113 1.14266 9.51471C1.42681 9.98829 2.04107 10.1419 2.51465 9.85771L4.00012 8.96642V18.8003C4.00012 19.9204 4.00012 20.4804 4.21811 20.9083C4.40986 21.2846 4.71582 21.5906 5.09214 21.7823C5.51996 22.0003 6.08002 22.0003 7.20012 22.0003H16.8001C17.9202 22.0003 18.4803 22.0003 18.9081 21.7823C19.2844 21.5906 19.5904 21.2846 19.7821 20.9083C20.0001 20.4804 20.0001 19.9204 20.0001 18.8003V8.96639L21.4857 9.85771C21.9592 10.1419 22.5735 9.98829 22.8576 9.51471C23.1418 9.04113 22.9882 8.42687 22.5146 8.14272L17.4927 5.12957L13.6515 2.81528C13.3723 2.64702 13.158 2.5179 12.9763 2.42081C12.9339 2.3971 12.8909 2.37505 12.8473 2.35465C12.6962 2.28089 12.5651 2.2318 12.4305 2.20213C12.147 2.13965 11.8533 2.13965 11.5697 2.20214C11.4348 2.23188 11.3034 2.28115 11.1518 2.35523C11.109 2.37529 11.0667 2.39695 11.0251 2.42021C10.8432 2.51737 10.6286 2.64667 10.3487 2.81528L6.50181 5.13303Z'
      fill='#7A7786'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 20V16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16V20H13ZM12 13C10.3431 13 9 14.3431 9 16V22H15V16C15 14.3431 13.6569 13 12 13Z'
      fill='#7A7786'
    />
  </svg>
);

const home = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 12H14V20H19C19.5523 20 20 19.5523 20 19V13C20 12.4477 19.5523 12 19 12ZM12 10V22H19C20.6569 22 22 20.6569 22 19V13C22 11.3431 20.6569 10 19 10H12Z'
      fill='#7A7786'
    />
    <path
      d='M18 15C18 14.4477 17.5523 14 17 14C16.4477 14 16 14.4477 16 15C16 15.5523 16.4477 16 17 16C17.5523 16 18 15.5523 18 15Z'
      fill='#7A7786'
    />
    <path
      d='M18 18C18 17.4477 17.5523 17 17 17C16.4477 17 16 17.4477 16 18C16 18.5523 16.4477 19 17 19C17.5523 19 18 18.5523 18 18Z'
      fill='#7A7786'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 22C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H11C12.6569 2 14 3.34315 14 5V22H5ZM5 4H11C11.5523 4 12 4.44772 12 5V20H10V19C10 18.4477 9.55229 18 9 18H7C6.44772 18 6 18.4477 6 19V20H5C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44771 4 5 4Z'
      fill='#7A7786'
    />
    <path
      d='M9 6C9.55229 6 10 6.44772 10 7C10 7.55228 9.55228 8 9 8H7C6.44772 8 6 7.55228 6 7C6 6.44772 6.44772 6 7 6H9Z'
      fill='#7A7786'
    />
    <path
      d='M9 10C9.55229 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12H7C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10H9Z'
      fill='#7A7786'
    />
    <path
      d='M10 15C10 14.4477 9.55229 14 9 14H7C6.44772 14 6 14.4477 6 15C6 15.5523 6.44772 16 7 16H9C9.55228 16 10 15.5523 10 15Z'
      fill='#7A7786'
    />
  </svg>
);

const profile = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12.0043 2C9.07027 2 6.69177 4.38864 6.69177 7.33517C6.69177 10.2817 9.07027 12.6703 12.0043 12.6703C14.9383 12.6703 17.3168 10.2817 17.3168 7.33517C17.3168 4.38864 14.9383 2 12.0043 2ZM12.0043 3.44767C14.1422 3.44767 15.8753 5.18816 15.8753 7.33517C15.8753 9.48218 14.1422 11.2227 12.0043 11.2227C9.8664 11.2227 8.1333 9.48218 8.1333 7.33517C8.1333 5.18816 9.8664 3.44767 12.0043 3.44767ZM9.83005 14.8209C9.05233 14.8749 8.26621 14.9859 7.4908 15.1521C5.99418 15.4604 4.79685 16.0763 4.28724 17.0999C4.09503 17.5002 3.99839 17.9288 4.00002 18.3627C3.99944 18.7935 4.0953 19.2227 4.28062 19.6153C4.76994 20.6271 5.8278 21.1997 7.25624 21.5171L7.51213 21.5705C8.26648 21.7407 9.05284 21.8553 9.84446 21.909C9.91189 21.9288 10.0726 21.9472 10.248 21.9561L10.3922 21.9615C10.4664 21.9633 10.5506 21.9637 10.676 21.9637C11.8138 22.0263 12.9934 22.0081 14.1675 21.9081C14.7932 21.8653 15.4231 21.7835 16.0477 21.6636L16.5151 21.5666C18.0576 21.2623 19.2126 20.6836 19.7186 19.6164C20.0937 18.8241 20.0937 17.9047 19.7188 17.1127C19.214 16.0483 18.0737 15.4744 16.5034 15.1509C15.8873 15.0194 15.2612 14.922 14.6307 14.8599L14.1697 14.8209C12.7259 14.6935 11.2738 14.6935 9.83005 14.8209ZM14.0436 16.263L14.0562 16.264C14.7799 16.3149 15.4991 16.4165 16.2087 16.568C17.3751 16.8083 18.1667 17.2067 18.4171 17.7348C18.6057 18.133 18.6057 18.5958 18.4169 18.9944C18.1829 19.4879 17.472 19.8691 16.4445 20.1021L16.2195 20.1498C15.496 20.3112 14.7791 20.4152 14.0576 20.4647C12.9379 20.5599 11.8249 20.5771 10.7148 20.5171L10.3212 20.5103C10.2119 20.5048 10.1198 20.4942 10.0345 20.4771C9.35872 20.4261 8.75066 20.3454 8.16027 20.2283L7.80775 20.1537C6.63771 19.9244 5.83915 19.5243 5.58024 18.989C5.48964 18.797 5.44125 18.5803 5.44154 18.3609C5.44073 18.1428 5.48848 17.931 5.58118 17.7379C5.83293 17.2324 6.67919 16.7971 7.78606 16.569C8.50073 16.4159 9.21962 16.3144 9.94294 16.264C11.316 16.143 12.6837 16.143 14.0436 16.263Z'
      fill='white'
      fill-opacity='0.9'
    />
  </svg>
);
const wallet = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.81204 2.5H16.188C19.3978 2.5 22 5.16116 22 8.44374V9.18592L22.0002 9.20241L22 9.21891V14.5201L22.0002 14.5366L22 14.5531V15.5563C22 18.8388 19.3978 21.5 16.188 21.5H7.81204C4.60219 21.5 2 18.8388 2 15.5563V8.44374C2 5.16116 4.60219 2.5 7.81204 2.5ZM20.5464 8.44374V8.45916H17.3504L17.1673 8.46431C15.4101 8.56244 14.0147 10.0491 14.0147 11.8695C14.0159 13.7531 15.5084 15.2787 17.3499 15.2798H20.5464V15.5563C20.5464 18.0179 18.595 20.0135 16.188 20.0135H7.81204C5.40498 20.0135 3.45358 18.0179 3.45358 15.5563V8.44374C3.45358 5.98214 5.40498 3.98651 7.81204 3.98651H16.188C18.595 3.98651 20.5464 5.98214 20.5464 8.44374ZM17.3508 9.94567L20.5464 9.94567V13.7933H17.3504L17.2033 13.7874C16.2329 13.7102 15.4689 12.8811 15.4683 11.869C15.4683 10.8074 16.3109 9.94632 17.3508 9.94567ZM18.521 11.8084C18.521 11.3979 18.1956 11.0652 17.7942 11.0652H17.4921L17.3935 11.072C17.0388 11.1212 16.7653 11.4322 16.7653 11.8084C16.7653 12.2189 17.0907 12.5517 17.4921 12.5517H17.7942L17.8928 12.5449C18.2475 12.4957 18.521 12.1847 18.521 11.8084ZM13.0807 7.74062C13.0807 7.33013 12.7553 6.99736 12.3539 6.99736H7.12203L7.0234 7.00415C6.66866 7.05337 6.39524 7.36434 6.39524 7.74062C6.39524 8.15111 6.72063 8.48388 7.12203 8.48388H12.3539L12.4525 8.47709C12.8073 8.42788 13.0807 8.1169 13.0807 7.74062Z'
      fill='#58536D'
      fill-opacity='0.9'
    />
  </svg>
);

const wallet2 = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M17.7689 8.3818H22C22 4.98459 19.9644 3 16.5156 3H7.48444C4.03556 3 2 4.98459 2 8.33847V15.6615C2 19.0154 4.03556 21 7.48444 21H16.5156C19.9644 21 22 19.0154 22 15.6615V15.3495H17.7689C15.8052 15.3495 14.2133 13.7975 14.2133 11.883C14.2133 9.96849 15.8052 8.41647 17.7689 8.41647V8.3818ZM17.7689 9.87241H21.2533C21.6657 9.87241 22 10.1983 22 10.6004V13.131C21.9952 13.5311 21.6637 13.8543 21.2533 13.8589H17.8489C16.8548 13.872 15.9855 13.2084 15.76 12.2643C15.6471 11.6783 15.8056 11.0736 16.1931 10.6122C16.5805 10.1509 17.1573 9.88007 17.7689 9.87241ZM17.92 12.533H18.2489C18.6711 12.533 19.0133 12.1993 19.0133 11.7877C19.0133 11.3761 18.6711 11.0424 18.2489 11.0424H17.92C17.7181 11.0401 17.5236 11.1166 17.38 11.255C17.2364 11.3934 17.1555 11.5821 17.1556 11.779C17.1555 12.1921 17.4964 12.5282 17.92 12.533ZM6.73778 8.3818H12.3822C12.8044 8.3818 13.1467 8.04812 13.1467 7.63649C13.1467 7.22487 12.8044 6.89119 12.3822 6.89119H6.73778C6.31903 6.89116 5.9782 7.2196 5.97333 7.62783C5.97331 8.04087 6.31415 8.37705 6.73778 8.3818Z'
      fill='white'
    />
  </svg>
);
const logout = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M21.4936 6.23864C21.3848 3.89119 19.4478 2.02197 17.0734 2.02197H12.1894L11.9806 2.0268C9.62755 2.13576 7.75438 4.07656 7.75438 6.45597V7.38897L7.76123 7.49074C7.81089 7.85682 8.12469 8.13897 8.50438 8.13897C8.9186 8.13897 9.25438 7.80319 9.25438 7.38897V6.45597L9.25936 6.28353C9.34859 4.74295 10.6256 3.52197 12.1894 3.52197H17.0734L17.2453 3.52694C18.781 3.61588 19.9984 4.88889 19.9984 6.44697V17.587L19.9934 17.7594C19.9041 19.3 18.6263 20.522 17.0634 20.522H12.1784L12.0066 20.517C10.472 20.428 9.25438 19.1543 9.25438 17.597V16.655L9.24754 16.5532C9.19788 16.1871 8.88408 15.905 8.50438 15.905C8.09017 15.905 7.75438 16.2408 7.75438 16.655V17.597L7.75949 17.8113C7.87131 20.1552 9.80715 22.022 12.1784 22.022H17.0634L17.2721 22.0171C19.6245 21.9081 21.4984 19.9662 21.4984 17.587V6.44697L21.4936 6.23864ZM12.3534 8.57653C12.6191 8.30972 13.0357 8.28466 13.3298 8.50191L13.414 8.57435L16.342 11.4904C16.6096 11.7568 16.6339 12.1749 16.415 12.469L16.4123 12.4721C16.3914 12.4999 16.3685 12.5263 16.344 12.5509L16.342 12.5532L13.414 15.4692C13.1205 15.7615 12.6457 15.7605 12.3534 15.467C12.0876 15.2002 12.0643 14.7835 12.2828 14.4903L12.3555 14.4064L13.9963 12.7718H3.77148C3.35727 12.7718 3.02148 12.436 3.02148 12.0218C3.02148 11.6421 3.30364 11.3283 3.66971 11.2786L3.77148 11.2718H13.9961L12.3555 9.63719C12.0887 9.37147 12.0637 8.95486 12.2809 8.6608L12.3534 8.57653Z'
      fill='#58536D'
      fill-opacity='0.9'
    />
  </svg>
);
const search = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M11.6115 2C6.30323 2 2 6.20819 2 11.3993C2 16.5903 6.30323 20.7985 11.6115 20.7985C13.8819 20.7985 15.9684 20.0287 17.613 18.7415L20.7371 21.7886L20.8202 21.8586C21.1102 22.0685 21.5214 22.0446 21.7839 21.7873C22.0726 21.5043 22.072 21.0459 21.7825 20.7636L18.6952 17.7523C20.2649 16.0794 21.2231 13.8487 21.2231 11.3993C21.2231 6.20819 16.9198 2 11.6115 2ZM11.6115 3.44774C16.1022 3.44774 19.7426 7.00776 19.7426 11.3993C19.7426 15.7908 16.1022 19.3508 11.6115 19.3508C7.12086 19.3508 3.48044 15.7908 3.48044 11.3993C3.48044 7.00776 7.12086 3.44774 11.6115 3.44774Z'
      fill='#58536D'
    />
  </svg>
);

const add = (
  <svg width='60' height='60' viewBox='0 0 60 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M47.9165 31.25H12.0835C10.9334 31.25 10 30.41 10 29.375C10 28.34 10.9334 27.5 12.0835 27.5H47.9165C49.0666 27.5 50 28.34 50 29.375C50 30.41 49.0666 31.25 47.9165 31.25Z'
      fill='white'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M29.375 50C28.34 50 27.5 49.0667 27.5 47.9167V12.0833C27.5 10.9333 28.34 10 29.375 10C30.41 10 31.25 10.9333 31.25 12.0833V47.9167C31.25 49.0667 30.41 50 29.375 50Z'
      fill='white'
    />
  </svg>
);

const icons = {
  filter,
  twitter,
  error,
  success,
  'error-outline': errorOutline,
  home,
  building,
  profile,
  wallet,
  wallet2,
  logout,
  search,
  add,
};

const Icon = ({ icon }) => {
  return icons[icon] ?? null;
};

export default Icon;
