import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Logo } from '.';
import { Link } from 'react-router-dom';
import Icon from './Icon';

const Mobile = ({ actions, top, className, sticky, hideLogo, mobileActions }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const _handleToggle = () => setIsCollapsed(!isCollapsed);

  const _handleScroll = () => setIsScrolled(window.scrollY > 20);
  useEffect(() => {
    _handleScroll();
    window.addEventListener('scroll', _handleScroll);
    return () => window.removeEventListener('scroll', _handleScroll);
  });

  return (
    <>
      <header
        style={top ? { paddingTop: 25 + top } : undefined}
        className={classNames(
          className,
          'mobile-header-wrapper flex justify-between items-center w-screen top-0 ',
          sticky ? 'fixed' : 'absolute',
          sticky && isScrolled && 'scrolled',
        )}
      >
        <Link to='/'>{!hideLogo && <Logo />}</Link>
        {!mobileActions && (
          <div onClick={_handleToggle} className={classNames('hamburger', isCollapsed && 'collapsed')}>
            <span />
            <span />
          </div>
        )}
        <div className={classNames('actions', mobileActions && 'mobile')}>
          {actions || (
            <>
              <Link to='/register' className={classNames('primary bg-gradient rounded-full')}>
                Register
              </Link>
              <Link to='/login'>Login</Link>
            </>
          )}
        </div>
        {!mobileActions && (
          <nav className={classNames('mobile-menu-wrapper', isCollapsed && 'collapsed')}>
            <Link className='regular' to='/'>
              <Icon icon='home' />
              <span>Home</span>
            </Link>
            <Link className='regular' to='/about'>
              <Icon icon='building' />
              <span>About us</span>
            </Link>
            <Link to='/register' className='primary bg-gradient'>
              <span>Register</span>
            </Link>
            <Link to='/login' className='secondary'>
              <span>Login</span>
            </Link>
          </nav>
        )}
      </header>
    </>
  );
};

export default Mobile;
