import classNames from 'classnames';
import { useRef } from 'react';
import Icon from './Icon';

const Input = ({ error, label, value, onChange, state, username }) => {
  const _handleChange = ({ target: { value } }) => onChange(value);
  const ref = useRef();

  const _handleClear = () => {
    onChange('');
    ref.current.focus();
  };
  return (
    <div className={classNames('input-wrapper', state?.toLowerCase())}>
      <input ref={ref} value={value} onChange={_handleChange} type='text' placeholder='@username' />
      {state === 'ERROR' && <Icon icon='error' />}
      {state === 'SUCCESS' && <Icon icon='success' />}
      {state === 'ERROR' ? (
        <span className='error' onClick={_handleClear}>
          <Icon icon='error-outline' />

          {error}
        </span>
      ) : (
        username && (
          <span>
            https://untitled.com/
            {value ? `@${value}` : <span>@username</span>}
          </span>
        )
      )}
    </div>
  );
};

export default Input;
