import { useEffect, useRef, useState } from 'react';
import Wallet from './Banner';

import { SortableContainer } from 'react-sortable-hoc';
import classNames from 'classnames';
import Icon from './Icon';

const WalletList = (props) => {
  const wrapper = useRef();
  const [placeholder, setPlaceholder] = useState(null);

  useEffect(() => {
    // const bbox = wrapper.current.getBoundingClientRect();
    if (props.placeholder) {
      setPlaceholder({
        x: props.placeholder.x,
        y: props.placeholder.y,
        width: props.placeholder.width,
        height: props.placeholder.height,
      });
    } else setPlaceholder(null);
  }, [JSON.stringify(props.placeholder)]);

  return (
    <main ref={wrapper}>
      <div className={classNames(props.items.length > 1 ? 'grid' : 'empty')}>
        {props.items.map((banner, i) => (
          <Wallet active={props.sorted === i} value={banner} index={i} />
        ))}
        {placeholder && (
          <div
            className='placeholder'
            style={{
              top: placeholder.y,
              left: placeholder.x,
              width: placeholder.width,
              height: placeholder.height,
            }}
          />
        )}

        {!props.items.length && <Empty />}
      </div>
    </main>
  );
};

const Empty = () => {
  return (
    <div className='empty-wrapper'>
      <img src='/images/empty.png' alt='Empty banner' />
      <h2>Nothing to see here</h2>
      <a className='bg-gradient'>
        <Icon icon='wallet2' />
        <span>Connect wallet</span>
      </a>
    </div>
  );
};

export default SortableContainer(WalletList);
