import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <div className='w-screen flex flex-col items-center hero-wrapper'>
      <h2>Show the world</h2>
      <p>
        A dope showroom for <strong>all</strong> your NFTs
      </p>
      <Link to='/username' className='bg-gradient rounded-full'>
        Claim username
      </Link>
    </div>
  );
};

export default Hero;
