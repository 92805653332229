import className from 'classnames';
import { useState } from 'react';
import Icon from './Icon';

const chevron = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M12 22C17.5229 22 22 17.5225 22 12C22 6.47745 17.5229 2 12 2C6.47779 2 2 6.47779 2 12C2 17.5222 6.47779 22 12 22ZM12 20.5C7.30621 20.5 3.5 16.6938 3.5 12C3.5 7.30621 7.30621 3.5 12 3.5C16.6944 3.5 20.5 7.30586 20.5 12C20.5 16.6941 16.6944 20.5 12 20.5ZM15.0237 14.0443C15.3168 14.2628 15.7335 14.2395 16.0004 13.9738C16.2939 13.6815 16.2949 13.2066 16.0027 12.9131L12.5317 9.42711L12.4474 9.35412C12.1534 9.13513 11.7352 9.15946 11.4687 9.42711L7.99773 12.9131L7.92529 12.9974C7.70807 13.2915 7.73318 13.7081 8.00002 13.9738L8.08429 14.0462C8.37837 14.2634 8.79498 14.2383 9.06067 13.9715L12.0012 11.02L14.9397 13.9715L15.0237 14.0443Z'
      fill='#58536D'
      fill-opacity='0.9'
    />
  </svg>
);

const ProfileBanner = ({ name, avatar }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const _handleToggle = () => setIsCollapsed(!isCollapsed);
  return (
    <div className='profile-banner-wrapper'>
      <div className={className('user', isCollapsed && 'collapsed')} onClick={_handleToggle}>
        <img src={avatar} alt={`${name}'s avatar`} />
        <span className='name'>{name}</span>
        {chevron}
      </div>
      <div className={className('options', isCollapsed && 'collapsed')}>
        <a className='bg-gradient option active'>
          <Icon icon='profile' />
          <span>Profile</span>
        </a>
        <a className='option'>
          <Icon icon='wallet' />
          <span>Wallet</span>
        </a>
        <a className='option'>
          <Icon icon='logout' />
          <span>Logout</span>
        </a>
      </div>
    </div>
  );
};

export default ProfileBanner;
