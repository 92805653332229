import Icon from './Icon';

const Search = () => {
  return (
    <div className='search-wrapper'>
      <Icon icon='search' />
      <input type='text' placeholder='Search' />
    </div>
  );
};

export default Search;
