import classNames from 'classnames';
import { useState } from 'react';
import Icon from '../Icon';
import Option from './Option';

const options = [
  {
    value: 'ETH',
    label: 'Ethereum',
  },
  {
    value: 'poly',
    label: 'Polygon',
  },
  {
    value: 'NBA',
    label: 'NBA Topshots',
  },
  {
    value: 'Binance',
    label: 'Binance Smart Chain',
  },
];

const Filter = () => {
  const [value, setValue] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const _handleSelect = (val) => () => {
    if (!val) setValue([]);
    else {
      const _value = [...value];
      if (_value.includes(val)) _value.splice(_value.indexOf(val), 1);
      else _value.push(val);
      setValue(_value);
    }
  };

  const _handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className='filter-wrapper'>
      <div className='title' onClick={_handleToggle}>
        <Icon icon='filter' /> <span>Filters</span>
      </div>
      <div className={classNames('select-wrapper', isCollapsed && 'collapsed')}>
        <Option onSelect={_handleSelect(null)} label='All' active={!value.length} />
        {options.map((option) => (
          <Option active={value.includes(option.value)} onSelect={_handleSelect(option.value)} label={option.label} />
        ))}
      </div>
    </div>
  );
};

export default Filter;
