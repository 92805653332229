import { useState, useRef } from 'react';
import { arrayMoveImmutable } from 'array-move';
import {
  Banner,
  Card,
  Features,
  Filter,
  Footer,
  Hero,
  Logos,
  MobileHeader,
  ProfileBanner,
  Search,
  Start,
  WalletList,
} from '../components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../components/Icon';

const banners = [
  { banner: '/images/banner.jpg', title: 'Snowden #1', value: '0.27 ETH', price: '$19,947.07', id: 1 },
  { banner: '/images/banner.jpg', title: 'Snowden #2', value: '0.27 ETH', price: '$19,947.07', id: 2 },
  { banner: '/images/banner.jpg', title: 'Snowden #3', value: '0.27 ETH', price: '$19,947.07', id: 3 },
  { banner: '/images/banner.jpg', title: 'Snowden #4', value: '0.27 ETH', price: '$19,947.07', id: 4 },
  { banner: '/images/banner.jpg', title: 'Snowden #5', value: '0.27 ETH', price: '$19,947.07', id: 5 },
];

const wallets = [
  {
    banner: '/images/wallet-1.png',
    title: 'Ethereum',
    address: '0x00213lksa08s8d7sl7l4335l112l898a',
  },
  {
    banner: '/images/wallet-2.png',
    title: 'Polygon',
    address: '0x00213lksa08s8d7sl7l433adf5l112l898a',
  },
];

const Profile = () => {
  const [items, setItems] = useState(wallets);
  const [placeholder, setPlaceholder] = useState(null);
  const [sorted, setSorted] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const nodeRefPositions = useRef([]);
  const timeout = useRef(null);

  const _handleSortStart = ({ node, nodes, index }) => {
    setPlaceholder(node.getBoundingClientRect());
    setSorted(index);
    nodeRefPositions.current = nodes.map((node) => {
      return node.node.getBoundingClientRect();
    });
  };

  const _handleSortEnd = ({ oldIndex, newIndex }) => {
    const _items = arrayMoveImmutable([...items], oldIndex, newIndex);
    setItems(_items);
    setPlaceholder(null);
    setSorted(null);
  };

  const _handleCopy = () => {
    setIsCopied(true);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setIsCopied(false), 480);
  };

  const _handleRemove = (address) => () => {
    const index = items.findIndex((item) => item.address === address);
    const _items = [...items];
    _items.splice(index, 1);
    setItems(_items);
  };
  return (
    <>
      <MobileHeader
        hideLogo
        mobileActions
        top={35}
        actions={<ProfileBanner name='Anders' avatar='/images/avatar.jpg' />}
        className='dashboard'
      />
      <div className='wallets-wrapper'>
        <nav>
          <h1>All connected wallets</h1>
          <Link to='/creation'>
            <Icon icon='add' />
            <span>Add new wallet</span>
          </Link>
        </nav>
        <main>
          {items.map((wallet) => (
            <Wallet {...wallet} key={wallet.address} onRemove={_handleRemove(wallet.address)} />
          ))}
          <Creation />
        </main>
      </div>
    </>
  );
};

const Creation = () => {
  return (
    <Link to='/creation'>
      <div className='creation-wrapper'>
        <Icon icon='add' />
      </div>
    </Link>
  );
};

const Wallet = ({ banner, title, address, onRemove }) => {
  return (
    <div className='wallet-wrapper'>
      <img src={banner} />
      <h2>{title}</h2>
      <p>{address}</p>
      <button onClick={onRemove}>Remove</button>
    </div>
  );
};
export default Profile;
